import React from 'react';
import IndexSection from './Index/Index';
import TeamCompliance from './TeamCompliance/TeamCompliance';
import ComplaintChannel from './ComplaintChannel/ComplaintChannel';
import useStyles from './Compliance.style';

const Compliance = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <IndexSection />
      <TeamCompliance />
      <ComplaintChannel />
    </div>
  );
};

export default Compliance;
