import React from 'react';
import { Typography, Container, Grid } from '@material-ui/core';
import useStyles from './TeamCompliance.styles';
import ExcellenceData from './TeamCompliance.data';
import peopleIcon from '../../../../../content/assets/icons/Compliance/people.svg';
import checkIcon from '../../../../../content/assets/icons/Compliance/check.svg';
import arrowIcon from '../../../../../content/assets/icons/Compliance/compliance-arrow.svg';

const TeamCompliance = () => {
  const { title, subtitle, links } = ExcellenceData;
  const classes = useStyles();
  return (
    <React.Fragment>
      <section className={classes.root} id="excelencia">
        <Container className={classes.container} maxWidth="lg">
          <div className={classes.teamComplianceAtributtes}>
            <Grid item>
              <Grid item className={classes.box1}>
                <Grid item xs={3} sm={2} className={classes.peopleIcon}>
                  <img src={peopleIcon} />
                </Grid>
                <p>
                  Contamos con un <strong>equipo de cumplimiento</strong> especializado en la
                  materia, que vela por las{' '}
                  <strong>buenas prácticas en nuestra empresa y que capacita constantemente</strong>{' '}
                  a cada uno de sus integrantes. Sin importar nuestro rol o función, en CurrencyBird
                  todos conocemos y seguimos cuidadosamente las directrices y principios definidos
                  en nuestro programa de cumplimiento.
                </p>
              </Grid>
              <Grid item className={classes.box2}>
                <Grid item xs={3} sm={2} className={classes.checkIcon}>
                  <img src={checkIcon} />
                </Grid>
                <p>
                  Creemos que parte importante de nuestro trabajo es{' '}
                  <strong>
                    estar siempre al día en materia regulatoria y colaborar en la prevención de
                    delitos, y en la transparencia y seguridad del rubro financiero digital.
                  </strong>{' '}
                  Por lo anterior, ponemos a tu disposición una vía para realizar denuncias, de
                  manera absolutamente confidencial, sobre prácticas o actividades que consideres
                  puedan ir en contra de la normativa vigente.
                </p>
                <Grid item xs={3} sm={2} className={classes.arrowIcon}>
                  <img src={arrowIcon} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default TeamCompliance;
