import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#074F71',
      backgroundColor: 'transparent ',
      '& #cumplimiento-tag': {
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '22px ',
        margin: 0,
        marginBottom: '13px',
      },
    },
    canalDenuncias: {
      background: 'transparent',
      color: '#36ADAC',
      borderRadius: '40px',
      fontSize: '22px',
      fontWeight: `bold`,
      width: `329px`,
      height: 59.28,
      textTransform: `none`,
      '&:hover': {
        background: '#fff',
        color: `#FAB234`,
        border: '2px solid #FAB234',
      },
    },
    title: {
      fontSize: '44px',
      color: '#36ADAC',
      maxWidth: '674px',
      fontWeight: 300,
      lineHeight: '58px',
      '& span': {
        fontWeight: 500,
      },
      marginBottom: '26px',
      marginBlockStart: '0',
    },
    subtitle: {
      maxWidth: 674,
      lineHeight: '22px',
    },
    container: {
      height: `auto`,
      marginTop: 72,
      paddingTop: 90,
    },
    firstSubSection: {
      paddingTop: '90px',
      marginBottom: '72px',
      maxWidth: '559px',
      lineHeight: '22px',
      '& h2': {
        fontSize: '22px',
        lineHeight: 1.6,
      },
    },

    downSectionResponsive: {
      paddingTop: 60,
    },
    [theme.breakpoints.down(1921)]: {
      container: {
        padding: '0 6%',
      },
      title: {
        fontSize: '44px',
      },
    },
    [theme.breakpoints.down(769)]: {
      root: {
        '& #cumplimiento-tag': {
          fontWeight: 'bold',
          fontSize: '18px',
          margin: 0,
          lineHeight: '20px',
          marginBottom: '9px',
        },
      },
      title: {
        fontSize: '44px',
        fontWeight: 300,
        width: '100%',
        lineHeight: 1.43,
      },
      subtitle: {
        width: '100%',
        lineHeight: '16px',
      },
      canalDenuncias: {
        background: '#fff',
        color: '#36ADAC',
        borderRadius: '40px',
        fontSize: '16px',
        fontWeight: `bold`,
        lineHeight: '29px',
        width: `282px`,
        height: 37,
        textTransform: `none`,
        '&:hover': {
          background: '#fff',
          color: `#FAB234`,
          border: '2px solid #FAB234',
        },
      },
      downSectionResponsive: {
        paddingTop: 48,
      },
      firstSubSection: {
        paddingTop: '104px',
        marginBottom: '72px',
        maxWidth: '100%',
        lineHeight: '22px',
        '& h2': {
          fontSize: '16px',
          lineHeight: '25px',
        },
      },
    },

    [theme.breakpoints.down(501)]: {
      root: {
        '& #cumplimiento-tag': {
          fontWeight: 'bold',
          fontSize: '18px',
          margin: 0,
          lineHeight: '22px',
          marginBottom: '13px',
        },
      },
      title: {
        fontSize: '30px',
        fontWeight: 300,
        width: '50%',
        lineHeight: '35px',
      },
      subtitle: {
        width: '60%',
        lineHeight: '18px',
      },
      container: {
        padding: '0 11.15%',
      },
      canalDenuncias: {
        background: '#fff',
        color: '#36ADAC',
        borderRadius: '40px',
        fontSize: '16px',
        fontWeight: `bold`,
        lineHeight: '29px',
        width: `282px`,
        height: 37,
        textTransform: `none`,
        '&:hover': {
          background: '#fff',
          color: `#FAB234`,
          border: '2px solid #FAB234',
        },
      },
      downSectionResponsive: {
        paddingTop: 48,
      },
      firstSubSection: {
        paddingTop: '104px',
        marginBottom: '72px',
        maxWidth: '559px',
        lineHeight: '22px',
        '& h2': {
          fontSize: '14px',
          lineHeight: '22px',
        },
      },
    },
  })
);

export default useStyles;
