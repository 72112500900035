import React from 'react';
import { Button, Container, Grid, Box } from '@material-ui/core';
import useStyles from './ComplaintChannel.styles';

const ComplaintChannel = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <section className={classes.root} id="complaintChannel">
        <Container className={classes.container}>
          <div className={classes.header}>
            <h2 className={classes.title}>
              Canal de <span>denuncias</span>
            </h2>
            <h2 className={classes.subtitle}>
              Si estás en conocimiento o eres testigo de algunos de los delitos establecidos en la{' '}
              <a
                href="https://www.bcn.cl/leychile/navegar?idNorma=1008668"
                target="_blank"
                className={classes.link}
              >
                <span>Ley 20.393</span>
              </a>
              , tales como lavado de activos, financiamiento del terrorismo, cohecho a funcionario
              público nacional y extranjero, receptación, negociación incompatible, corrupción entre
              privados, apropiación indebida, administración desleal y otros; puedes cooperar en la
              prevención y persecución de estos comunicándote con el oficial de cumplimiento de
              CurrencyBird, a través de nuestro canal de denuncia, o escribiendo directamente a{' '}
              <a href="mailto:denuncias@currencybird.cl" target="_blank" className={classes.link}>
                <span>denuncias@currencybird.cl</span>
              </a>
            </h2>
          </div>
          <div className={classes.complaintChannelAtributtes}>
            <Grid container>
              <Grid item xs={12} sm={12} md={8} className={classes.box1}>
                <p>
                  La denuncia puede ser realizada de forma anónima o incluyendo tus datos personales
                  y de contacto.
                </p>
              </Grid>
              <Grid item xs={12} sm={12} md={4} className={classes.downSectionResponsive}>
                <Box id="subsectionBox" display="flex" justifyContent="center" m={1} p={1}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    href="mailto:denuncias@currencybird.cl"
                    className={classes.canalDenuncias}
                    onClick={(e) => {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({
                        event: 'buttonClick',
                        category: 'Cumplimiento',
                        action: 'click_canaldenuncias',
                        label: 'Canal de denuncias, click en boton canal de denuncias',
                      });
                    }}
                  >
                    Canal de denuncias
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default ComplaintChannel;
