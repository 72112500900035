import React from 'react';
import { Typography, Container, Grid, Button } from '@material-ui/core';
import useStyles from './Index.styles';
import IndexData from './Index.data';

const Index = () => {
  const { title, subtitle, links } = IndexData;
  const classes = useStyles();
  return (
    <React.Fragment>
      <section className={classes.root} id="compliance">
        <Container className={classes.container} maxWidth="lg">
          <Grid container>
            <Grid className={classes.firstSubSection} item xs={12} md={12}>
              <div id="cumplimiento-tag">Cumplimiento</div>
              <Grid item xs={12}>
                <h1 className={classes.title}>Por un ecosistema financiero transparente</h1>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.subtitle}>
                  <Typography variant="h2">
                    En CurrencyBird estamos comprometidos con la{' '}
                    <strong>prevención de fraudes</strong> y de delitos como el lavado de activos y
                    financiamiento del terrorismo. Por esto trabajamos con rigurosas políticas,
                    procedimientos y controles de cumplimiento en cada una de las transferencias que
                    realizamos.
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.downSectionResponsive}>
                <Button
                  onClick={(e) => {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      event: 'buttonClick',
                      category: 'Cumplimiento',
                      action: 'click_canaldenuncias',
                      label: 'Cumplimiento, click en boton canal de denuncias',
                    });
                  }}
                  className={classes.canalDenuncias}
                  href="mailto:denuncias@currencybird.cl"
                  id="ChannelButton"
                  variant="outlined"
                  color="inherit"
                >
                  Canal de denuncias
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default Index;
