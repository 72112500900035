import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Compliance from '../components/Landing/Compliance/Compliance';
import SEO from '../components/SEO';

const PAGE_TITLE = 'Políticas de Cumplimiento y Seguridad';
const PAGE_DESCRIPTION =
  'En CurrencyBird, promovemos un ecosistema financiero seguro y transparente. Explora nuestras pollíticas de cumplimiento.';

const CompliancePage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} />
      <Compliance />
    </>
  );
};

export default CompliancePage;

export const pageQuery = graphql`
  query CompliancePage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
