import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import background from '../../../../../content/assets/icons/Compliance/compliance_brackground_2.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#ffff',
      backgroundPosition: `250% 180%`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#36ADAC ',
      backgroundImage: `url(${background})`,
      backgroundSize: 'auto',
      padding: '100px 0',

      '& img': {
        width: '40px',
        height: '40px',
      },
    },
    complaintChannelAtributtes: {
      '& p': {
        fontSize: '22px',
      },
      width: '100%',
      display: 'flex',
      padding: '110px 115px',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
    },
    canalDenuncias: {
      background: '#fff',
      color: '#36ADAC',
      borderRadius: '40px',
      fontSize: '22px',
      fontWeight: `bold`,
      width: `329px`,
      height: 59.28,
      textTransform: `none`,
      '&:hover': {
        background: '#fff',
        color: `#FAB234`,
        border: '2px solid #FAB234',
      },
    },
    downSectionResponsive: {
      float: `left`,
      marginTop: 60,
    },
    box1: {
      float: `left`,
      maxWidth: 559,
      marginTop: 60,
      display: `block`,
      textAlign: `left`,
      backgroundColor: `transparent`,
      '& p': {
        fontSize: 18,
      },
    },
    link: {
      color: '#ffff',
      fontWeight: 'normal',
      fontSize: '30px',
      textAlign: 'center',
    },
    title: {
      margin: '0',
      textAlign: 'center',
      fontSize: '48px',
      fontWeight: 'normal',
      '& span': {
        fontWeight: 600,
        fontSize: '48px',
      },
    },
    container: {
      padding: '0 6%',
    },
    header: {
      padding: '0 115px',
    },
    subtitle: {
      margin: '0',
      marginTop: '78px',
      fontWeight: 'normal',
      fontSize: '22px',
      textAlign: 'left',
      lineHeight: '30px',
      maxWidth: 904,
      '& span': {
        fontWeight: 600,
        lineHeight: '30px',
        fontSize: '22px',
      },
    },
    [theme.breakpoints.down(1921)]: {
      container: {
        padding: '0 6%',
      },
      canalDenuncias: {
        background: '#fff',
        color: '#36ADAC',
        borderRadius: '40px',
        fontSize: '16px',
        fontWeight: `bold`,
        width: `329px`,
        height: 46,
        textTransform: `none`,
        '&:hover': {
          background: '#fff',
          color: `#FAB234`,
          border: '2px solid #FAB234',
        },
      },
      header: {
        padding: '0 115px',
      },
      complaintChannelAtributtes: {
        padding: '0 115px',
      },
    },
    [theme.breakpoints.down(768)]: {
      root: { padding: '40px 21px' },
      title: {
        margin: '0',
        textAlign: 'left',
        fontSize: '31px',
        fontWeight: 'normal',
        '& span': {
          fontWeight: 600,
          fontSize: '31px',
        },
      },
      header: {
        padding: '0 40px',
      },
      excellenceAtributtes: {
        '& p': {
          fontSize: '16px',
        },
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      complaintChannelAtributtes: {
        '& p': {
          fontSize: '16px',
          fontStyle: 'italic',
        },
        width: '100%',
        display: 'flex',
        marginTop: 49,
        flexWrap: 'wrap',
        boxSizing: 'border-box',
      },
      canalDenuncias: {
        background: '#fff',
        color: '#36ADAC',
        borderRadius: '40px',
        fontSize: '16px',
        fontWeight: `bold`,
        width: `282px`,
        height: 57,
        textTransform: `none`,
        '&:hover': {
          background: '#fff',
          color: `#FAB234`,
          border: '2px solid #FAB234',
        },
      },
      downSectionResponsive: {
        float: `left`,
        marginTop: 51,
      },
      subtitle: {
        margin: '0',
        marginTop: '40px',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'left',
        lineHeight: '30px',
        maxWidth: '100%',
        '& span': {
          fontWeight: 600,
          fontSize: '16px',
        },
      },
      container: {
        padding: '0 11.15%',
      },
    },
    [theme.breakpoints.down(501)]: {
      root: {},
      container: {
        padding: '0 6%',
      },
      header: {
        padding: '0 0',
      },
      title: {
        margin: '0',
        textAlign: 'left',
        fontSize: '31px',
        fontWeight: 'normal',
        '& span': {
          fontWeight: 600,
          fontSize: '31px',
        },
      },
      excellenceAtributtes: {
        '& p': {
          fontSize: '16px',
        },
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      complaintChannelAtributtes: {
        '& p': {
          fontSize: '16px',
          fontStyle: 'italic',
        },
        padding: '0 0',
        width: '100%',
        display: 'flex',
        marginTop: 0,
        flexWrap: 'wrap',
        boxSizing: 'border-box',
      },
      canalDenuncias: {
        background: '#fff',
        color: '#36ADAC',
        borderRadius: '40px',
        fontSize: '16px',
        fontWeight: `bold`,
        width: `282px`,
        height: 57,
        textTransform: `none`,
        '&:hover': {
          background: '#fff',
          color: `#FAB234`,
          border: '2px solid #FAB234',
        },
      },
      downSectionResponsive: {
        float: `left`,
        marginTop: 35,
      },
      box1: {
        float: `left`,
        maxWidth: '100%',
        marginTop: 48,
        display: `block`,
        textAlign: `left`,
        backgroundColor: `transparent`,
        '& p': {
          fontSize: 16,
        },
      },
      subtitle: {
        margin: '0',
        marginTop: '40px',
        fontWeight: 'normal',
        fontSize: '16px',
        textAlign: 'left',
        lineHeight: '20px',
        '& span': {
          fontWeight: 600,
          fontSize: '16px',
        },
      },
    },
  })
);

export default useStyles;
