import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#ffff',
      backgroundSize: '936px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: 'rgba(235, 247, 247, 0.8)',
      padding: '122px 21px',
      '& img': {
        width: 'auto',
        height: 'auto',
      },
    },
    container: {},
    teamComplianceAtributtes: {
      '& p': {
        fontSize: '22px',
        lineHeight: '30px',
      },
      '& .MuiGrid-grid-sm-2': {
        textAlign: 'left',
      },
      '& .MuiGrid-grid-xs-3': {
        textAlign: 'left',
      },
      width: '100%',
      display: 'flex',
      color: '#004F71',
      flexWrap: 'wrap',
      boxSizing: 'border-box',
    },
    box1: {
      float: `left`,
      maxWidth: 487,
      display: `block`,
      textAlign: `left`,
      backgroundColor: `transparent`,
    },
    box2: {
      float: `left`,
      maxWidth: 489,
      marginTop: 95,
      display: `block`,
      marginLeft: 162,
      textAlign: `left`,
      backgroundColor: `transparent`,
    },
    arrowIcon: {
      float: `right`,
      marginTop: 25,
    },
    arrowIconImage: {
      width: '49px',
      height: '49px',
    },
    links: {
      color: '#ffff',
    },
    title: {
      margin: '0',
      textAlign: 'center',
      fontSize: '72px',
      fontWeight: 'normal',
      '& span': { fontWeight: 'bold' },
    },
    subtitle: {
      margin: '0',
      marginLeft: '80px',
      marginRight: '80px',
      paddingTop: '16px',
      fontWeight: 'normal',
      fontSize: '22px',
      textAlign: 'center',
      color: '#004F71',
    },
    [theme.breakpoints.down(1921)]: {
      root: {
        padding: '122px 21px',
        '& img': {
          width: 'auto',
          height: 'auto',
        },
      },
      peopleIcon: {
        display: 'block',
        marginRight: 'auto',
      },
      checkIcon: {
        display: 'block',
        marginRight: 'auto',
      },
      title: {
        fontSize: '31px',
      },
      excellenceAtributtes: {
        '& p': {
          fontSize: '16px',
        },
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      subtitle: {
        marginLeft: '80px',
        marginRight: '80px',
      },
      container: {
        padding: '0 6%',
      },
    },
    [theme.breakpoints.down(768)]: {
      root: {
        padding: '40px 21px',
        '& img': {
          width: 'auto',
          height: 'auto',
        },
      },
      peopleIcon: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      checkIcon: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      title: {
        fontSize: '31px',
      },
      teamComplianceAtributtes: {
        '& p': {
          fontSize: '16px',
        },
        '& .MuiGrid-grid-sm-2': {
          textAlign: 'left',
        },
        '& .MuiGrid-grid-xs-3': {
          textAlign: 'left',
        },
        width: '100%',
        display: 'flex',
        color: '#004F71',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
      },
      box1: {
        float: `left`,
        maxWidth: '100%',
        display: `block`,
        fontSize: 16,
        textAlign: `left`,
        backgroundColor: `transparent`,
        lineHeight: '30px',
      },
      box2: {
        float: `left`,
        maxWidth: '100%',
        display: `block`,
        fontSize: 16,
        textAlign: `left`,
        marginTop: 74,
        marginLeft: 0,
        backgroundColor: `transparent`,
        lineHeight: '30px',
      },
      excellenceAtributtes: {
        '& p': {
          fontSize: '16px',
        },
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      subtitle: {
        marginLeft: '20px',
        marginRight: '20px',
      },
      container: {},
    },
    [theme.breakpoints.down(501)]: {
      root: {
        padding: '40px 21px',
        '& img': {
          width: 'auto',
          height: 'auto',
        },
      },
      peopleIcon: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      arrowIconImage: {
        width: '49px',
        height: '49px',
      },
      checkIcon: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      title: {
        fontSize: '31px',
      },
      teamComplianceAtributtes: {
        '& p': {
          fontSize: '16px',
        },
        '& .MuiGrid-grid-sm-2': {
          textAlign: 'left',
        },
        '& .MuiGrid-grid-xs-3': {
          textAlign: 'left',
        },
        width: '100%',
        display: 'flex',
        color: '#004F71',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
      },
      box1: {
        float: `left`,
        maxWidth: 279,
        display: `block`,
        fontSize: 16,
        textAlign: `left`,
        backgroundColor: `transparent`,
        lineHeight: '20px',
      },
      box2: {
        float: `left`,
        maxWidth: 279,
        display: `block`,
        fontSize: 16,
        textAlign: `left`,
        marginTop: 74,
        marginLeft: 0,
        backgroundColor: `transparent`,
        lineHeight: '20px',
      },
      excellenceAtributtes: {
        '& p': {
          fontSize: '16px',
        },
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      subtitle: {
        marginLeft: '20px',
        marginRight: '20px',
      },
      container: {},
    },
  })
);

export default useStyles;
