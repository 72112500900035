import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../../../content/assets/icons/Compliance/complianceBackground.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundImage: `url(${background})`,
      backgroundSize: '770px',
      backgroundPosition: '100% 0%',
      backgroundRepeat: 'no-repeat',
    },
    [theme.breakpoints.down(500)]: {
      root: {
        backgroundSize: '70%',
      },
    },
    [theme.breakpoints.down(1280)]: {
      root: {
        backgroundSize: '50%',
      },
    },
    [theme.breakpoints.down(768)]: {
      root: {
        backgroundSize: '50%',
      },
    },
  })
);

export default useStyles;
